//get body for reqContent
const bdy = document.body;
/* get element with class = `copyrite` */
const copyrite = document.getElementsByClassName("copyrite");
/* get element with class = `slides` */
const slides = document.getElementsByClassName("slides");
/* get currentDate */
const date = new Date();
/* getFullYear() currentDate */
const year = date.getFullYear();
//get admin table
const table = document.getElementsByClassName("table")[0];
const dashboard_btn = window.toggle_dashhide;
const blog_medias = document.getElementsByTagName("div");
const sidebar = document.getElementById("sidebar");

// injecting fullyear for copyrite
for(copy of copyrite){
  copy.firstChild.insertAdjacentText("beforeend", `${year}`);
}

const atagHref = document.querySelectorAll("a");

atagHref.forEach((atag) => {
  atag.addEventListener("click", (e) =>
    e.target.getAttribute("href") === "#" ? e.preventDefault() : null
  );
});

//  onclick event on <table class="table"> if table exists
if (table) table.onclick = viewRecord;

function viewRecord(e) {
  //checkfor className `Records`
  if (e.target.className === "records") {
    let view_Records = document.getElementById("viewRecords");
    view_Records.textContent = `${e.target.innerText}`;
    //Toggle className `d-none`
    view_Records.classList.toggle("d-none");
  }
}

bdy.addEventListener("click", ajaxElement);
bdy.addEventListener("mousedown", ajaxElement);
bdy.addEventListener("pointerdown", ajaxElement);

function ajaxElement(e) {
  //checking whether element contains class reqContent
  if (/\breqContent\b/g.test(e.target.className)) {
    e.target.addEventListener("click", fetchUrl);
    // console.log(/\breqContent\b/g.test(e.target.className));
  }
}

let httpReq;
//map headers
const { type, value } = { x: "Cache-Contol", y: "no-cache" };
//AJAX TEMPLATE FOR INJECTION
const ajax_template = document.getElementById("template");

//fectch urls
function fetchUrl(e) {
  //request method
  const reqType = "GET";
  //request url
  const reqURL = location.origin+`/${e.target.id}`;
  sendReq(reqType, reqURL);
}

function sendReq(reqType, reqURL) {
  httpReq = new XMLHttpRequest();
  if (!httpReq) {
    alert("does not support instance XMLHttpRequest");
    return false;
  }
  httpReq.onreadystatechange = getContents;
  httpReq.onerror = () => {
    throw new Error("failed to Execute Request");
  };
  httpReq.open(reqType, reqURL, true);
  httpReq.send();
  // httpReq.setRequestHeader(type,value);
}
//get contents
function getContents() {
  if (httpReq.readyState === XMLHttpRequest.DONE) {
    if (httpReq.status === 200) {
      injectContent(httpReq.responseText);
    } else {
      injectContent(`
        <div class="w-100 h-100vh p-5 bg-secondary text-white text-center"><h1 class="text-danger">404<h1><p>Page Not Found</p>
        <div>We recommend using Chrome or Opera Mini.</div>
        </div>
        `);
    }
  }
}
//inject contents from requests
function injectContent(reqText) {
  ajax_template.innerHTML = reqText;
  //scroll window smoothly to the top
  window.scrollTo(0, {
    behaviour: "smooth",
  });
  // console.log(reqText);
}
if (dashboard_btn) dashboard_btn.addEventListener('click', dashboardSidebar);
if(sidebar)sidebar.style.height=`${window.innerHeight}px`;

function dashboardSidebar() {
let dashhides = document.querySelectorAll('.dash-hide');
  dashhides.forEach(
    dashhide=> dashhide.classList.toggle('d-none')
  );
}



for (const blog_media of blog_medias) {
  if (blog_media.getAttribute("data-type") != null) {
    if (blog_media.dataset.type.toLowerCase().indexOf(".mp4") > -1) {
      blog_media.insertAdjacentHTML(
        "afterbegin",
        `<video controls controlslist="nodownload" muted width="100%">
  <source src=${blog_media.dataset.type} type="video/mp4">
</video>`
      );
    }
    if (blog_media.dataset.type.toLowerCase().indexOf(".mp4") < 0) {
      blog_media.insertAdjacentHTML(
        "afterbegin",
        `<img src='${blog_media.dataset.type}' alt='${blog_media.dataset.alt}' width="100%">`
      );
    }
  }
}

if (slides.length) {
  let start = 0;
  fadeIn();
  function fadeIn() {
    for (let i = 0; i < slides.length; i++) {
      let slide = slides[i];
      slide.style.display = "none";
      slide.classList.add("fade-in");
    }
    start++;
    if (start > slides.length) start = 1;
    slides[start - 1].style.display = "";
    setTimeout(fadeIn, 5000);
  }
}


document.querySelectorAll("[data-date]")
    .forEach(elem=>{
      const {date} = elem.dataset;
      elem.innerHTML = moment(date).fromNow();
    })


const btn_top = document.querySelector(".move_up");

if(btn_top){
  btn_top.addEventListener('click',
      ()=>window.scrollTo({top: 0, behavior: 'smooth'}));



  window.addEventListener('scroll', function (){
    if (this.scrollY>=1000) btn_top.classList.remove('d-none');
    else btn_top.classList.add('d-none');
  });
}




















